/* I'm using DESKTOP First approach */
.App {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* my css - default/desktop */
.header {
  font-size: 34px;
  display: flex;
  flex-direction: column;
}

.name {
  color: #3498db; 
}

.header-text {
  text-align: center;
}

.header-image {
  text-align: center;
}

.header-image img {
  max-width: 20%;
  height: auto;
  border-radius: 50%;
}

.about-container {
  font-size: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.header {
  margin-bottom: 1em;
}

/* navbar (desktop default) */
.navbar {
  position: static;
  background-color: #333;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 5px solid #333;
  border-radius: 15px 15px 15px 15px; 
}

.navbar-brand {
  font-weight: bold;
  color: white;
  text-decoration: none; 
}

.menu-center .navbar-list {
  display: flex;
  align-items: center;
  justify-content: center;
}

.menu-right .navbar-list {
  display: flex;
  justify-content: flex-end;
}

.navbar-link {
  color: white;
  text-decoration: none;
}

.navbar-link:hover {
  text-decoration: underline;
  color: #0077ff;
  font-weight: bold;
  font-style: italic;
  border-radius: 4px;
}

.navbar-item {
  margin: 0 5px;
}

.menu-toggle {
  display: none; 
}

.menu {
  display: none; 
}

.my-principal-color {
  color: blue;
}

.info {
  margin-top: 1em;
  text-align: center;
}

.main-contact {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.main-content {
  flex: 1;
}

.contact-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-size: 24px;
}

.contact a {
  color: rgba(15, 16, 15, 0.913);
  text-decoration: none;  
}

.contact i {
  margin-right: 5px;
}

/* buttons (desktop default) */
.custom-primary-btn {
  padding: 10px 20px;
  margin: 10px;
  border: none;
  border-radius: 14px;
  background-color: blue;
  color: white;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.custom-primary-btn {
  background-color: #0056b3;
}

.custom-danger-btn {
  padding: 10px 20px;
  margin: 10px;
  border: none;
  border-radius: 14px;
  background-color: #dc3545;
  color: white;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.custom-danger-btn:hover {
  background-color: #c82333;
}

.my-button {
  background-color: blue;
  color: white;
  padding: 10px;
  border-radius: 15px;
  border: none;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease;
  width: 200px;
}

.pdf-button:hover {
  background-color: darkblue;
}
/* experience css */
.experience-section {
  margin-top: 30px;
}

.experience-cards {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
  padding: 15px;
}

.experience-card {
  display: flex;
  flex-direction: column;
  border: 1px solid #ddd;
  border-radius: 8px;
  max-width: 655px;
  padding: 16px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.experience-card:hover, .card:hover {
  box-shadow: 0px 0px 30px rgba(123, 121, 238, 0.8);
}

.bullet-list {
  list-style-type: none; 
  padding-left: 20px;   
}

.bullet-list li {
  position: relative;   
}

.bullet-list li::before {
  content: "-";         
  position: absolute;   
  left: -15px;          
  color: #000;          
}

.experience-card h3, h4{
  margin: 0;
  padding: 3px;
}

.experience-card p {
  margin: 3px; 
}

.experience-card ul {
  margin-top: 10px; 
  padding-left: 20px; 
}

.certif-item {
  /* margin-bottom: 20px; */
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}
.certif-title {
  margin-bottom: 5px; 
}
.certif-organization {
  margin-top: 0;
  margin-bottom: 10px; 
}
.certif-description {
  margin-bottom: 10px;
}

li {
  list-style-type: none;
  text-align: left;
}

.speech-button {
  background-color: rgb(52, 159, 240);
  color: white;
  border: none;
  border-radius: 3px;
  font-size: 20px;
  cursor: pointer;
}

.get-in-touch-container {
  background-color: #f9f9f9;
  padding: 20px;
  text-align: center;
  margin-top: 20px;
}

.cta-container p {
  font-size: 1.2em;
}

/* cards (default) */
.card-container {
  display: grid;
  gap: 15px;
  padding: 15px;
}

.card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border: 1px solid #ddd;
  border-radius: 4px;
  overflow: hidden;
  transition: box-shadow 0.3s ease;
  width: 100%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.card-img {
  width: 100%;
  height: 200px;
  object-fit: contain;
  background-color: #f0f0f0;
}

.card-body {
  padding: 15px;
}

.card-title {
  font-size: 1.25em;
  margin-bottom: 10px;
}

.card-text {
  flex: 1;
  margin-bottom: 15px;
}

.card-footer {
  padding: 10px 15px;
  background: #f8f8f8;
  border-top: 1px solid #ddd;
}

.card-btn {
  display: inline-block;
  padding: 10px 15px;
  margin-bottom: 10px;
  color: #fff;
  background-color: #007bff;
  text-decoration: none;
  border-radius: 4px;
  transition: background-color 0.3s ease;
}

.card-btn.code {
  background-color: #007bff; 
}

.card-btn.code:hover {
  background-color: #0056b3;
}

.card-btn.demo {
  background-color: #28a745; 
}

.card-btn.demo:hover {
  background-color: #1e7e34;
}
/* modal (default) */
.modal-overlay {
  position: fixed;
  top: 10px;
  left: 10px;
  right: 10px;
  bottom: 10px;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  overflow: auto;
}

.modal-content {
  padding: 20px;
  width: 500px;
  max-width: 50%;
  background-color: white;
  border-radius: 10px;
  z-index: 1001;
  position: relative;
  max-height: 90vh;
  overflow-y: auto;
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #ddd;
  margin-bottom: 20px;
}

.modal-title {
  margin: 0;
}

.close-btn {
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
}

.modal-body {
  margin-bottom: 20px;
}

.form-group {
  margin-bottom: 15px;
}

label {
  display: block;
  margin-bottom: 5px;
}

input,
textarea {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.modal-footer {
  display: flex;
  justify-content: flex-end;
  border-top: 1px solid #ddd;
  padding-top: 15px;
}

.modal-btn {
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-right: 5px;
}

.modal-btn-primary {
  background-color: #007bff;
  color: white;
}
.modal-btn-cancel {
  background-color: #9a9a9a;
  color: white;
}
.modal-btn-danger {
  background-color: #b30000;
  color: white;
}

.modal-btn-cancel:hover {
  background-color: #383535;
}
.modal-btn-primary:hover {
  background-color: #0056b3;
}
.modal-btn-danger:hover {
  background-color: #c34a4a;
}

.open-modal-btn {
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  background-color: #007bff;
  color: white;
  cursor: pointer;
}

.open-modal-btn:hover {
  background-color: #0056b3;
}

/* footer (default) */
.footer {
  background-color: #333;
  color: #fff;
  text-align: center;
  padding: 10px 0;
  left: 0;
  bottom: 0;
  width: 100%;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

/* Large desktop: min-width 1200px */
@media (min-width: 1200px) {
  .card-container {
    grid-template-columns: repeat(4, 1fr); 
  }
}

/* Medium desktop: 992px to 1199px */
@media (min-width: 992px) and (max-width: 1199px) {
  .card-container {
    grid-template-columns: repeat(3, 1fr); 
  }
}


/* TABLET CSS */

@media (min-width: 768px) and (max-width: 991px) {
  .card-container {
    grid-template-columns: repeat(2, 1fr); 
  }
}

/* MOBILE CSS */

@media (max-width: 767px) {
  .navbar {
    flex-direction: column;
    align-items: flex-start;
  }

  .menu-center, .menu-right {
    display: none;
  }

  .menu-toggle {
    display: block;
    align-self: flex-start;
    background-color: rgb(52, 159, 240);
  }

  .menu {
    display: none;
    flex-direction: column;
    align-self: flex-start; 
  }

  .menu.active {
    display: flex;
  }

  .card-container {
    grid-template-columns: 1fr; 
  }
}
